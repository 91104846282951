<template>
  <TTView>
    <VRow>
      <VCol cols="12">
        <CompanyUserCard
          :user="currentUser"
          :person-id="personId"
          :selected-staff-position="selectedFullStaffPosition"
          :company="currentCompany"
          :is-direct-link="!!staffPosition"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VTabs>
          <VTab
            :to="{
              name : Names.R_ORGSTRUCTURE_USER_COMPANY_HIERARCHY,
              query : { ...$route.query }
            }"
          >
            Иерархия
          </VTab>

          <VTab
            :to="{
              name : Names.R_ORGSTRUCTURE_USER_COMPANY_COMPETENCY_GROUPS,
              query : { ...$route.query }
            }"
          >
            Группы компетенций
          </VTab>

          <VTab
            :to="{
              name : Names.R_ORGSTRUCTURE_USER_COMPANY_ANOTHER_CONNECTS,
              query : { ...$route.query }
            }"
          >
            Другие связи
          </VTab>
        </VTabs>
      </VCol>
    </VRow>

    <RouterView />
  </TTView>
</template>

<script>
import CompanyUserCard from '../../components/authentication/CompanyUserCard.vue';

export default {
  name: 'OrgUserCompany',
  components: { CompanyUserCard },
  inject: ['Names'],
  props: {
    staffPosition: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedStaffPosition: null,
      selectedFullStaffPosition: {},
      currentUser: {},
      currentCompany: {},
      usersStaffPositions: [],
    };
  },
  computed: {
    /**
     *
     * @returns {string}
     */
    personId() {
      return this.$route.params.personId;
    },

    /**
     *
     * @returns {string}
     */
    companyId() {
      return this.$route.params.companyId;
    },

    queryStaffPosition() {
      return this.$route.query.staffPosition;
    },
  },

  watch: {
    usersStaffPositions(staffPositions) {
      const staffPosition = staffPositions.find((sp) => sp.main);
      if (staffPosition && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPosition.id;
      }
      if (staffPositions.length && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPositions[0].id;
      }

      this.selectedFullStaffPosition = staffPositions.find((el) => el.id === this.selectedStaffPosition);
    },
    queryStaffPosition(queryStaffPosition) {
      this.selectedStaffPosition = queryStaffPosition;
    },
  },

  async created() {
    this.currentUser = await this.getUser(this.personId);
    const { company } = await this.getCompany(this.companyId);
    this.currentCompany = company;
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.selectedStaffPosition = this.queryStaffPosition;
        const { staffPositions } = await this.$di.api.Orgstructure.getStaffPositionsByUser({ userId: this.personId });
        this.usersStaffPositions = this.staffPositionsCompanyFilter(staffPositions);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    staffPositionsCompanyFilter(positions) {
      return positions.filter((el) => el.companyId === this.companyId);
    },

    async getUser(id) {
      try {
        return await this.$di.api.Authentication.getUser({ id });
      } catch (error) {
        this.$di.notify.errorHandler(error);
        this.$di.redirect.errorHandler404(error);
        return {};
      }
    },

    async getCompany(id) {
      try {
        return await this.$di.api.Account.getCompany({ id });
      } catch (error) {
        this.$di.notify.errorHandler(error);
        this.$di.redirect.errorHandler404(error);
        return {};
      }
    },
  },
};
</script>

<style scoped>
/* HOTFIX */
::v-deep .v-select.v-text-field input {
  position: relative;
}
</style>
