var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('CompanyUserCard',{attrs:{"user":_vm.currentUser,"person-id":_vm.personId,"selected-staff-position":_vm.selectedFullStaffPosition,"company":_vm.currentCompany,"is-direct-link":!!_vm.staffPosition}})],1)],1),_c('VRow',[_c('VCol',[_c('VTabs',[_c('VTab',{attrs:{"to":{
            name : _vm.Names.R_ORGSTRUCTURE_USER_COMPANY_HIERARCHY,
            query : Object.assign({}, _vm.$route.query)
          }}},[_vm._v(" Иерархия ")]),_c('VTab',{attrs:{"to":{
            name : _vm.Names.R_ORGSTRUCTURE_USER_COMPANY_COMPETENCY_GROUPS,
            query : Object.assign({}, _vm.$route.query)
          }}},[_vm._v(" Группы компетенций ")]),_c('VTab',{attrs:{"to":{
            name : _vm.Names.R_ORGSTRUCTURE_USER_COMPANY_ANOTHER_CONNECTS,
            query : Object.assign({}, _vm.$route.query)
          }}},[_vm._v(" Другие связи ")])],1)],1)],1),_c('RouterView')],1)}
var staticRenderFns = []

export { render, staticRenderFns }