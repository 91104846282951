var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[(_vm.isDirectLink)?_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1):_vm._e(),_c('VToolbarTitle',[_vm._v(" Связи по ШД ")])],1),_c('VCardText',{staticClass:"user-info-container"},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"type"},[_vm._v("Пользователь")]),_c('RouterLink',{staticClass:"name ml-4",attrs:{"to":{
          name : _vm.Names.R_AUTHENTICATION_USER,
          params : {
            userId : _vm.personId
          }
        }}},[_vm._v(" "+_vm._s(_vm.fio)+" ")]),_c('div',{staticClass:"type-id tt-light-mono-46--text ml-5"},[_vm._v(" "+_vm._s(_vm.personId)+" ")])],1),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"type"},[_vm._v("Штатная должность")]),_c('RouterLink',{staticClass:"name ml-4",attrs:{"to":{
          name : _vm.Names.R_ORGSTRUCTURE_POSITION_EDIT,
          params : {
            positionId : _vm.selectedStaffPosition.id
          }
        }}},[_vm._v(" "+_vm._s(_vm.selectedStaffPosition.name)+" ")]),_c('div',{staticClass:"type-id tt-light-mono-46--text ml-5"},[_vm._v(" "+_vm._s(_vm.selectedStaffPosition.id)+" ")])],1),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"type"},[_vm._v("Компания")]),_c('RouterLink',{staticClass:"name ml-4",attrs:{"to":{
          name : _vm.Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE,
          params : {
            accountId : _vm.company.accountId,
            companyId : _vm.company.id
          }
        }}},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('div',{staticClass:"type-id tt-light-mono-46--text ml-5"},[_vm._v(" "+_vm._s(_vm.company.id)+" ")])],1),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"type"},[_vm._v("Команда")]),_c('RouterLink',{staticClass:"name ml-4",attrs:{"to":{
          name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM,
          params : {
            companyId : _vm.company.id,
            teamId : _vm.selectedStaffPosition.teamId
          }
        }}},[_vm._v(" "+_vm._s(_vm.selectedStaffPosition.teamName)+" ")]),_c('div',{staticClass:"type-id tt-light-mono-46--text ml-5"},[_vm._v(" "+_vm._s(_vm.selectedStaffPosition.teamId)+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }