<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        v-if="isDirectLink"
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        Связи по ШД
      </VToolbarTitle>
    </VToolbar>

    <VCardText class="user-info-container">
      <div class="d-flex mb-2">
        <div class="type">Пользователь</div>
        <RouterLink
          class="name ml-4"
          :to="{
            name : Names.R_AUTHENTICATION_USER,
            params : {
              userId : personId
            }
          }"
        >
          {{ fio }}
        </RouterLink>
        <div class="type-id tt-light-mono-46--text ml-5">
          {{ personId }}
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="type">Штатная должность</div>
        <RouterLink
          class="name ml-4"
          :to="{
            name : Names.R_ORGSTRUCTURE_POSITION_EDIT,
            params : {
              positionId : selectedStaffPosition.id
            }
          }"
        >
          {{ selectedStaffPosition.name }}
        </RouterLink>
        <div class="type-id tt-light-mono-46--text ml-5">
          {{ selectedStaffPosition.id }}
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="type">Компания</div>
        <RouterLink
          class="name ml-4"
          :to="{
            name : Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE,
            params : {
              accountId : company.accountId,
              companyId : company.id
            }
          }"
        >
          {{ company.name }}
        </RouterLink>
        <div class="type-id tt-light-mono-46--text ml-5">
          {{ company.id }}
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="type">Команда</div>
        <RouterLink
          class="name ml-4"
          :to="{
            name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
            params : {
              companyId : company.id,
              teamId : selectedStaffPosition.teamId
            }
          }"
        >
          {{ selectedStaffPosition.teamName }}
        </RouterLink>
        <div class="type-id tt-light-mono-46--text ml-5">
          {{ selectedStaffPosition.teamId }}
        </div>
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_USER } from './common';

export default {
  name: 'CompanyUserCard',
  inject: ['Names'],
  props: {
    isDirectLink: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({ ...DEFAULT_USER }),
    },
    personId: {
      type: String,
      default: '',
    },
    selectedStaffPosition: {
      type: Object,
      default: () => ({}),
    },
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fio() {
      const { user } = this;
      return `${user.firstName} ${user.lastName}`;
    },
  },
};
</script>

<style scoped>
 .user-info-container {
   padding-left: 40px;
 }

 .type {
   width: 150px;
   min-width: 150px;
   font-weight: bold;
 }

.name {
  min-width: 400px;
}

.type-id {
  min-width: 270px;
}
</style>
